<template>
  <div>
    <b-container class="post-breadcrumb-spacer">
      <div class="text-center mt-4 mb-4">
        <h2 class="font-weight-normal">
          Use one of our lesson plans or build your own
        </h2>
      </div>
      <b-row class="justify-content-center pt-4 mb-4">
        <b-col cols="12" xl="4" lg="5"
               class="mb-5 mb-lg-0">
          <b-card :img-src="SPENV.S3_URL + '/images/classes-thumbs/lesson-plans.jpg'" img-alt="select lesson plan">
            <b-card-title class="font-weight-bold">
              Lesson plans
            </b-card-title>
            <b-card-text>
              <p class="lead font-size-14">
                Choose from one of our pre-created lesson plans to create your
                assignments easily.
              </p>
              <ul class="pl-3 font-size-12">
                <li class="pl-3 pb-2">
                  Add pre-created assignments automatically
                </li>
                <li class="pl-3 pb-2">
                  Includes video lesson and/or exercises
                </li>
                <li class="pl-3 pb-2">
                  Assignment lengths appropriate for your learners
                </li>
              </ul>
            </b-card-text>
            <div slot="footer" class="text-right">
              <b-button :to="{ name: 'LessonPlanName' }" variant="primary" class="text-right">
                Use lesson plan
              </b-button>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12" xl="4" lg="5">
          <b-card :img-src="SPENV.S3_URL + '/images/classes-thumbs/custom-plan.jpg'" img-alt="select lesson plan">
            <b-card-title class="font-weight-bold">
              Start from scratch
            </b-card-title>
            <b-card-text>
              <p class="lead font-size-14">
                Set up and configure your own custom lesson plan from scratch.
              </p>
              <ul class="pl-3 font-size-12">
                <li class="pl-3 pb-2">
                  Manually create each assignment
                </li>
                <li class="pl-3 pb-2">
                  Mix and match topics from our library
                </li>
                <li class="pl-3 pb-2">
                  Assignment durations must be considered to optimize the learning experience
                </li>
              </ul>
            </b-card-text>
            <div slot="footer" class="text-right">
              <b-button :href="SPENV.APP_URL + '/lmsapp/multigroups/new/'" variant="primary">
                Start from scratch
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'LessonPlanCreatePath',
};
</script>
